import React, {
  useState,
  useEffect,
  createRef,
  useMemo,
  startTransition,
} from "react"
import List from "@mui/material/List"
import ReactTimeAgo from "react-time-ago"
import Controls from "./controls/Controls"
import { useForm, Form } from "./useForm"
import db from "../Firestore"
import * as dataServices from "../pages/services/dataServices"
import { useSnackbar } from "notistack"
import { useHistory, withRouter, useLocation } from "react-router-dom"
import firebase from "firebase/compat/app"
import IconButton from "@mui/material/IconButton"
import YesNo from "./YesNo"
import FileStage from "./FileStage"
import * as fileServices from "../pages/services/fileServices"
import * as jobServices from "../pages/services/jobServices"
import AssignmentIcon from "@mui/icons-material/Assignment"
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates"
import {
  isNonEmptyString,
  isErrors,
  isNonZero,
} from "./controls/FieldValidation"
import {
  Alert,
  CircularProgress,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography,
  colors,
} from "@mui/material"
import * as Roles from "../pages/services/roleServices"
import _ from "lodash"
import { Box } from "@mui/material"
import { selectJobGridPagination } from "../redux/selectors"
import * as icons from "../icons"
import ProgressBackdrop from "./ProgressBackdrop"
import {
  getStorage,
  ref,
  deleteObject,
  getDownloadURL,
  listAll,
} from "firebase/storage"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import AllocateJobsDialog from "./AllocateJobsDialog"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore"
import History from "./History"
import { spacing } from "../pages/services/styleServices"
import ActionDialog from "./ActionDialog"
import ActionPanels from "./ActionPanels"
import NavButtons from "./NavButtons"
import {
  analyseIfSelfRepairable,
  getJobQualityAnalysisWithFunctions,
} from "../pages/services/chatGenerationServices"
import { useSelector } from "react-redux"
import FileListItem from "./FileListItem"
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
} from "firebase/firestore"
import SubHeading from "./SubHeading"
import ScrollingImageList from "./ScrollingImageList"
import JobQualityAnalysis from "./JobQualityAnalysis"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { HomeRepairService } from "@mui/icons-material"
import UserCombobox from "./controls/UserCombobox"

const initialValues = () => {
  return {
    label: "",
    description: "",
    category: "",
    centre_id: "",
    location: "",
    priority: 0,
    status: jobServices.JOB_STATUS_PENDING,
    // any images or other files uploaded to the job
    docs: [],
    file_info: [],
    supply_parts: false,
    supplier_id: "",
    work_order_id: "",
    self_repairable: false,
    self_repair_reason: "",
    created: dataServices.localTimestamp(),
    modified: dataServices.localTimestamp(),
    // job closed timestamp
    closed: null,
    history: [],
  }
}

const JobEditForm = (props) => {
  const history = useHistory()

  const [isShowProgress, setShowProgress] = useState(false)

  // per-Centre locations
  const [locations, setLocations] = useState([])

  const location = useLocation()

  const { setTitle } = props

  const addFileRef = createRef()

  const { enqueueSnackbar } = useSnackbar()

  const [jobToShowPhotos, setJobToShowPhotos] = useState(null)

  const [userEmail, setUserEmail] = useState("")

  const [jobId, setJobId] = useState("")

  const [currentUser, setCurrentUser] = useState()

  // For uploading files before the new job has been saved
  const [filesForNewJob, setFilesForNewJob] = useState([])

  const [userCentreIds, setUserCentreIds] = useState(undefined)

  const [claims, setClaims] = useState()

  const [docRef, setDocRef] = useState()

  const [jobQualityAnalysis, setJobQualityAnalysis] = useState([])

  const [jobRegulations, setJobRegulations] = useState([])

  const [jobPrevention, setJobPrevention] = useState([])

  const [jobRiskAssessment, setJobRiskAssessment] = useState([])

  const [isCheckingJobQuality, setCheckingJobQuality] = useState(false)

  const [showHistory, setShowHistory] = useState(false)

  const pag = useSelector(selectJobGridPagination)

  // Jobs in the work order that this job belongs to, if opened from a work order
  const [jobs, setJobs] = useState([])

  const [showAction, setShowAction] = useState(false)

  const [jobStatusMenuEl, setJobStatusMenuEl] = useState(null)

  const [comments, setComments] = useState([])

  const [hasNonClosedJobActions, setHasNonClosedJobActions] = useState(false)

  const [priorities, setPriorities] = useState()

  // Keep track of the original centre_id so we can check if it has changed
  // and update the centre stored in any actions for this job
  const [originalCentreId, setOriginalCentreId] = useState(undefined)

  const isNew = () => props.id === undefined && jobId === ""

  const [allocateDialogOpen, setAllocateDialogOpen] = useState(false)

  const [supplier, setSupplier] = useState()

  const { values, setValues, handleInputChange } = useForm(initialValues())

  const [isSupplierViewing, setSupplierViewing] = useState(true)

  const [selectedCarouselIndex, setSelectedCarouselIndex] = useState()

  // counter to enable triggering reload of files for this job.
  // increment to trigger the useEffect to reload files
  const [fileActions, setFileActions] = useState(0)

  const [jobTypes, setJobTypes] = useState([])

  const [userRoles, setUserRoles] = useState([])

  const [showAdminFields, setShowAdminFields] = useState(false)

  const [showFieldErrors, setShowFieldErrors] = useState(false)

  const fieldValidation = {
    label: [isNonEmptyString],
    centre_id: [isNonEmptyString],
    location: [isNonEmptyString],
    category: [isNonEmptyString],
    priority: [isNonZero],
    user_id: [isNonEmptyString],
    description: [isNonEmptyString],
  }

  const jobStatusMenuItems = useMemo(() => {
    const statuses = jobServices.getAvailableJobStatuses(
      values.status,
      isSupplierViewing
    )

    return statuses.map((item) => (
      <MenuItem
        key={item.value}
        onClick={() => {
          dataServices
            .changeJobStatus(
              jobId,
              values.account_id,
              values.work_order_id,
              item.value
            )
            .then(() => {
              setValues({ ...values, status: item.value })
              setJobStatusMenuEl(null)
            })
        }}
      >
        <ListItemIcon>
          <item.icon />
        </ListItemIcon>
        <Typography component={"span"}>{item.label}</Typography>
      </MenuItem>
    ))
  }, [values, jobId, isSupplierViewing])

  const createJobTypesOptions = (jobTypes) => {
    const lookupValues = jobTypes.lookup_values.map((lookup_value) => ({
      id: lookup_value,
      title: lookup_value,
    }))
    setJobTypes(lookupValues)
  }

  const [accountId, setAccountId] = useState()

  const [accountType, setAccountType] = useState()

  const [workOrderModified, setWorkOrderModified] = useState()

  const [workOrderStatus, setWorkOrderStatus] = useState()

  const [imageLoadInfo, setImageLoadInfo] = useState()

  // Listen for changes to this job
  useEffect(() => {
    // Check for centre id since that means a job is loaded, not just the default blank job values
    if (jobId && values && values.centre_id && claims) {
      // Listen for changes to this job
      const unsub = db
        .collection("jobs")
        .doc(jobId)
        .onSnapshot(
          (doc) => {
            if (doc.exists) {
              const docsBefore = Array.from(new Set(values.docs))
              const docsAfter = Array.from(new Set(doc.data().docs))
              const isDocsChanged = docsBefore.length !== docsAfter.length
              const isStatusChanged = values.status !== doc.data().status

              const newData = doc.data()

              if (isDocsChanged || isStatusChanged) {
                setValues((curr) => ({
                  ...curr,
                  docs: docsAfter,
                  file_info: _.uniqBy("file_name", newData.file_info),
                  status: newData.status,
                }))
              }
            }
          },
          (error) => {
            console.error("Error listening for job changes", error)
          }
        )

      return unsub
    }
  }, [jobId, values, claims])

  useEffect(() => {
    if (location?.state?.parent) {
      setValues((curr) => {
        const { parent } = location.state

        const newValues = {
          ...curr,
          parent_id: parent.parent_id,
          parent_type: parent.parent_type,
        }
        if (parent.parent_ref) {
          newValues.parent_ref = parent.parent_ref
        }

        if (parent.centre_id) {
          newValues.centre_id = parent.centre_id
        }

        return newValues
      })
    }
  }, [location])

  useEffect(() => {
    if (values.work_order_id) {
      // Listen for changes to that work order

      const unsub = db
        .collection("work_orders")
        .where(
          firebase.firestore.FieldPath.documentId(),
          "==",
          values.work_order_id
        )
        .onSnapshot(
          (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
              setWorkOrderModified(change.doc.data().modified)
              setWorkOrderStatus(change.doc.data().status)
            })
          },
          (error) => {
            console.error("Error listening for work order changes", error)
          }
        )

      return unsub
    }
  }, [values.work_order_id])

  useEffect(() => {
    if (jobToShowPhotos && values && jobId) {
      const currentFiles = jobToShowPhotos.docs.length
      const newFiles = values.docs.length
      const isDocsChanged = currentFiles !== newFiles

      if (isDocsChanged) {
        setJobToShowPhotos({ ...values, id: jobId })
      }
    }
  }, [jobToShowPhotos, jobId, values])

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      //setUser(user)
      user.getIdTokenResult().then((token) => {
        setAccountId(token.claims.account_id)
        if (token.claims.email_verified) {
          setUserEmail(token.claims.email)
        }
        setCurrentUser(token)
        setClaims(token.claims)
        setAccountType(token.claims.account_type)
        setUserRoles(token.claims.roles)
        setSupplierViewing(token.claims.account_type === "supplier")
        setShowAdminFields(
          token.claims.roles.includes(Roles.JOB_ADMIN) ||
            token.claims.roles.includes(Roles.ADMIN)
        )

        setValues((curr) => {
          let newValues = { ...curr }

          // Default the user's centre if they belong to exactly 1 centre
          if (token.claims.centre_ids.length === 1) {
            if (values.centre_id === "") {
              newValues = {
                ...newValues,
                centre_id: token.claims.centre_ids[0],
              }
            }
          }

          if (isNew()) {
            console.log("%cisNew", "color:yellow", { values, token })
            newValues = {
              ...newValues,
              account_id: token.claims.account_id,
              user_id: token.claims.user_id,
            }
          }
          return newValues
        })

        if (token.claims.roles.includes(Roles.JOB_ADMIN)) {
          setUserCentreIds([]) // all centres
        } else {
          setUserCentreIds(token.claims.centre_ids)
        }
      })
    })

    return unsub
  }, [])

  useEffect(() => {
    if (location?.state?.context && values.work_order_id && accountId) {
      // console.log(
      //   "%clocation.state.context",
      //   "color:yellow",
      //   location.state.context
      // )

      db.collection("jobs")
        .where("account_id", "==", accountId)
        .where("work_order_id", "==", values.work_order_id)
        .get()
        .then((querySnapshot) => {
          const jobs = querySnapshot.docs.map((doc) => {
            const data = doc.data()
            return { id: doc.id, ...data }
          })
          setJobs(jobs)
        })
    }
  }, [location, values.work_order_id, accountId])

  // Listen for comments for the job
  //TODO: remove ability for supplier to see comment, and delete firebase indexes for supplier access to comments, and update firestore.rules
  useEffect(() => {
    if (jobId && values?.account_id) {
      let query = db
        .collection("comments")
        .where("parent_id", "==", jobId)
        .where("type", "==", "job")

      if (claims.account_type === Roles.SUPPLIER) {
        query = query.where("supplier_account_id", "==", claims.account_id)
      } else {
        query = query.where("account_id", "==", values.account_id)
      }

      query = query.orderBy("created", "asc")

      // const query = db
      //   .collection("comments")
      //   .where("parent_id", "==", jobId)
      //   .where("account_id", "==", values.account_id)
      //   .where("type", "==", "job")
      //   .orderBy("created", "asc")

      const unsub = query.onSnapshot((querySnapshot) => {
        querySnapshot.docChanges().forEach(
          (change) => {
            const id = change.doc.id
            const data = change.doc.data()
            const comment = { ...data, id }

            // console.log(
            //   "%ccomment change",
            //   "color:lightgreen",
            //   change.type,
            //   comment
            // )

            if (change.type === "added") {
              setComments((comments) => [comment, ...comments])
            }
            if (change.type === "modified") {
              setComments((comments) =>
                comments.map((c) => (c.id === id ? comment : c))
              )
            }
            if (change.type === "removed") {
              setComments((comments) => comments.filter((c) => c.id !== id))
            }
          },
          (error) => console.error("error listening for comment changes", error)
        )
      })

      return unsub
    }
  }, [values?.account_id, jobId])

  // Initialise account if it a new record

  useEffect(() => {
    if (props.id === null || props.id === undefined) {
      if (accountId === undefined) {
        return
      }

      setValues((curr) => {
        const newValues = {
          ...curr,
          account_id: accountId,
        }

        return newValues
      })
    }
  }, [accountId, props])

  useEffect(() => {
    if (jobId && values?.account_id) {
      jobServices
        .hasNonClosedJobActions(jobId, values.account_id)
        .then((result) => {
          setHasNonClosedJobActions(result)
        })
    }
  }, [jobId, values.account_id])

  // Load job types

  useEffect(() => {
    if (values.account_id !== undefined && values.account_id !== "") {
      let query = db
        .collection("lookups")
        .where("account_id", "==", values.account_id)
        .where("name", "==", "job_types")

      try {
        dataServices
          .loadData("(Load job type lookup values)", query, false)
          .then((jobTypes) => {
            if (jobTypes.length === 1) {
              const types = jobTypes[0]
              if (
                values.category !== "" &&
                !types.lookup_values.includes(values.category)
              ) {
                types.lookup_values.push(values.category)
              }
              createJobTypesOptions(types)
            }
          })
      } catch (error) {
        console.log("Error loading job types", error)
      }
    }
  }, [values.account_id])

  useEffect(() => {
    // If we've deleted a 'job_types' lookup that was used in this job
    // then add this job's category back in temporarily as a category
    // value, i.e. so that it displays in the Category select.
    if (values.category !== "") {
      const found = jobTypes.find((jobType) => jobType.id === values.category)
      if (!found) {
        setJobTypes((curr) => [
          ...curr,
          { id: values.category, title: values.category },
        ])
      }
    }
  }, [values.category])

  const [loadState, setLoadState] = useState({ id: "", accountId: "" })

  useEffect(() => {
    if (claims === undefined) {
      return
    }

    const newLoadState = { id: props.id, accountId: claims.account_id }

    const isLoadStateChanged = !_.isEqual(newLoadState, loadState)

    if (!isLoadStateChanged) {
      return
    }

    setLoadState(newLoadState)

    if (props.id !== null && props.id !== undefined) {
      loadJob({ jobId: props.id, claims })
    } else {
      // Since this is a new job, load priorities for the current account
      jobServices.getJobPriorities(accountId).then((priorities) => {
        setPriorities(priorities)
      })
    }
  }, [props, claims])

  const handleJobOwnerChange = (user) => {
    if (user) {
      setValues((curr) => {
        const newValues = {
          ...curr,
          user_id: user.id,
        }

        return newValues
      })
    }
  }

  const handleJobOwnerClear = () => {
    setValues((curr) => {
      const newValues = {
        ...curr,
        user_id: "",
      }

      return newValues
    })
  }

  const selfRepairFields = useMemo(() => {
    if (claims?.roles.includes(Roles.ADMIN)) {
      return {
        disabled: false,
        visible: true,
      }
    } else {
      return {
        disabled: true,
        visible: values?.self_repairable,
      }
    }
  }, [values, claims])

  // Load job

  const loadJob = async ({ jobId, claims }) => {
    const logId = "[JOB EDIT > LOAD JOB]"

    if (jobId) {
      setJobId(jobId)

      // Clear this since we're loading a new job
      setJobQualityAnalysis([])

      const snapshot = await db.collection("jobs").doc(jobId).get()

      setDocRef(snapshot)

      const data = {
        ...initialValues(),
        ...snapshot.data(),

        // For pre-July 1st jobs we need to build the job 'docs' attribute
        //docs: await jobServices.getJobFileNames(snapshot.data(), jobId),
      }

      // Temporary - to deal with old jobs that have a string value for self_repairable
      if (data.self_repairable === "yes") {
        data.self_repairable = true
      } else if (data.self_repairable === "no") {
        data.self_repairable = false
      }

      // Check if there are an empty strings in the 'docs' array and remove them
      if (data.docs) {
        data.docs = data.docs.filter((doc) => doc !== "")
      }

      if (data.supplier_id === null) {
        data.supplier_id = ""
      }

      if (data.work_order_id === null) {
        data.work_order_id = ""
      }

      // These fields have moved to the work order
      delete data.require_quote
      delete data.quote_details

      // Keep track of the original centre_id, so we can update any
      // actions that are associated with this job, since the action
      // stores a copy of the centre id at the time the action was created.
      setOriginalCentreId(data.centre_id)

      if (claims.roles.includes(Roles.JOB_ADMIN)) {
        setUserCentreIds([]) // all centres
      } else {
        const allowedCentreIds = claims.centre_ids
        if (!allowedCentreIds.includes(data.centre_id)) {
          allowedCentreIds.push(data.centre_id)
        }
        setUserCentreIds(allowedCentreIds)
      }

      //console.log("%cdata", "color:pink", { data })

      // Deduplicate docs and file_info by file_name

      const newDocs = Array.from(new Set(data.docs))
      const newFileInfo = _.uniqBy(data.file_info, "file_name")

      //console.log("%cdeduped", "color:pink", { newDocs, newFileInfo })

      data.docs = newDocs
      data.file_info = newFileInfo

      setValues(data)

      // Since this is an existing job, load the priorities for the job's account
      // which means we get the right result regardless of what account
      // is viewing the job, e.g. creating account, or supplier account.
      jobServices.getJobPriorities(data.account_id).then((priorities) => {
        setPriorities(priorities)
      })

      setImageLoadInfo({
        docId: jobId,
        docType: "job",
        accountId: data.account_id,
        images: data.docs,
        fileInfo: data.file_info,
      })

      // Load supplier details

      if (data.supplier_id !== "") {
        db.collection("suppliers")
          .doc(data.supplier_id)
          .get()
          .then((doc) => {
            if (doc.exists) {
              setSupplier({ id: doc.id, ...doc.data() })
            } else {
              console.error(`Supplier ${data.supplier_id} not found`)
            }
          })
          .catch((error) => {
            console.log("Error loading supplier", error)
          })
      } else {
        setSupplier(null)
      }
    }
  }

  useEffect(() => {
    if (jobId && values.label) {
      setTitle(values.label)
    } else {
      if (location.state?.parent) {
        // Can be a label for a checklist, or asset, which are the possible optional parent types for a job
        setTitle(`Create Job - ${location.state.parent.parent_label}`)
      } else {
        setTitle("Create Job")
      }
    }

    setValues((curr) => {
      if (curr.description === "" && location.state?.parent) {
        return {
          ...curr,
          description: `Created from ${location.state?.parent?.parent_type} ${location.state?.parent?.parent_label}`,
        }
      } else {
        return curr
      }
    })
  }, [values, jobId, location])

  // Update combobox with centre locations if centre changes
  useEffect(() => {
    const checkClearLocation = (locations) => {
      if (locations.indexOf(values.location) === -1) {
        if (values.location !== "") {
          setValues((curr) => {
            const newValues = {
              ...curr,
              location: "",
            }

            return newValues
          })
        }
      }
    }

    if (values.centre_id && values.centre_id !== "") {
      dataServices
        .getCentreById(values.centre_id)
        .then((centre) => {
          const locationOptions = [{ id: "", label: "-- Select --" }]
          const isLocations =
            centre !== undefined && centre.locations !== undefined

          if (isLocations) {
            centre.locations.forEach((location) =>
              locationOptions.push({ id: location, label: location })
            )

            // If new centre doesn't have the previous location then blank out the location
            checkClearLocation(centre.locations)
          } else {
            checkClearLocation([])
          }
          setLocations(locationOptions)
        })
        .catch((error) => console.log(error))
    }
  }, [values, setValues])

  const handleUploadFile = () => {
    addFileRef.current.click()
  }

  const handleUpdateJobField = ({ item }) => {
    const newValues = {
      ...values,
    }

    newValues[item.key] = item.value

    setValues(newValues)
  }

  const handlePrint = () => {
    history.push("/JobPrint/" + jobId)
  }

  const handleStoreFile = async (event) => {
    const newFiles = []
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i]
      newFiles.push(file)
    }

    setShowProgress(true)

    jobServices
      .uploadFiles(jobId, values, newFiles, currentUser)
      .then((newJob) => {
        setValues(newJob)
        setShowProgress(false)

        // Clear file input field
        event.target.value = null

        setImageLoadInfo({
          docId: jobId,
          docType: "job",
          accountId: values.account_id,
          images: newJob.docs,
          fileInfo: newJob.file_info,
        })
      })
  }

  const handleDeleteJobConfirmed = async (jobIdToDelete) => {
    // Reset prompt flag, so if we'd clicked No, we can still click Delete again and get prompted
    const deleteConfig = {
      ...yesNoDeleteJobConfig,
      openPrompt: false,
    }
    setYesNoDeleteJobConfig(deleteConfig)

    const folderToDelete = `accounts/${accountId}/jobs/${jobIdToDelete}`
    const folderPath = `${folderToDelete}/`
    const storageRef = ref(getStorage(), folderPath)

    await listAll(storageRef).then(async (listResult) => {
      listResult.items.forEach(async (itemRef) => {
        await deleteObject(itemRef)
      })
    })

    await db.collection("jobs").doc(jobIdToDelete).delete()

    history.goBack()
    enqueueSnackbar("Deleted", { variant: "success" })
  }

  const [yesNoDeleteJobConfig, setYesNoDeleteJobConfig] = useState({
    openPrompt: false,
    description: "This delete is permanent",
    title: "Delete",
    handleConfirm: null,
  })

  const [yesNoDeleteFileConfig, setYesNoDeleteFileConfig] = useState({
    openPrompt: false,
    description: "Delete file from job?",
    title: "Delete",
    handleConfirm: null,
  })

  const getPriorityLabels = () => {
    const priorityLabels = priorities.lookup_values.map((p) => ({
      id: p.id,
      label: `${p.title} (${p.description})`,
    }))
    return priorityLabels
  }

  const handleAnalyseSelfRepairable = () => {
    analyseIfSelfRepairable(values).then((result) => {
      console.log("result", result)
    })
  }

  const handleAnalyseJobQuality = async () => {
    // Check if industry and location set

    const account = await dataServices.getAccountById(values.account_id)

    if (!account.industry) {
      enqueueSnackbar("Set your industry in My Organisation > My Account", {
        variant: "info",
      })
      return
    }

    const centre = await dataServices.getCentre(values.centre_id)

    if (!centre.state && !centre.country) {
      enqueueSnackbar("Set the centre's location - state and country", {
        variant: "info",
      })
      return
    }

    startTransition(() => {
      setCheckingJobQuality(true)
    })
    setJobQualityAnalysis([
      { key: "Checking", value: "Can take up to 20 seconds, please wait" },
    ])
    getJobQualityAnalysisWithFunctions({
      job: values,
      priorities,
      priorityLabels: getPriorityLabels(),
      jobTypes,
      locations,
      industry: account.industry,
      centre,
    }).then((result) => {
      const items = []
      // Work out ID value for suggested priority

      if (result.priority) {
        const priorityValue = getPriorityLabels().find(
          (item) => item.label === result.priority
        )
        console.log("priorityValue", { priorityValue, result })
        if (priorityValue.id === values.priority) {
          // No change to priority
          delete result.priority
        } else {
          result.priority = priorityValue.id
        }
      }

      setJobRegulations(result.regulations || [])
      delete result.regulations

      setJobPrevention(result.prevention)
      delete result.prevention

      setJobRiskAssessment(result.risk_assessment)
      delete result.risk_assessment

      console.log("%cresult", "color:lightgreen", result)

      // Remove any values that exactly match with the current job
      Object.keys(result).forEach((key) => {
        const value = result[key]
        const existingValue = values[key]

        const isBlank =
          existingValue === "" ||
          existingValue === null ||
          (key === "priority" && existingValue === 0)

        const reason = result[`${key}_reason`] || ""

        if (value !== existingValue) {
          // Capitalize first letter of key

          const newItem = { key, value, existingValue, isBlank, reason }

          // See values to show in the UI
          if (key === "priority") {
            const labels = getPriorityLabels()
            newItem.valueToShow = labels.find(
              (item) => item.id === value
            )?.label
            newItem.existingValueToShow = labels.find(
              (item) => item.id === existingValue
            )?.label
          } else {
            newItem.valueToShow = value
            newItem.existingValueToShow = existingValue
          }
          items.push(newItem)
        }
      })

      // Delete array entries that have keys ending in '_reason', as these would be merged with the main item above

      const filteredItems = items.filter(
        (item) => !item.key.endsWith("_reason")
      )

      //console.log("filteredItems", filteredItems)
      // Add a label for each item.
      // Add a label is 'label' then use 'Subject', otherwise capitalize first letter

      filteredItems.forEach((item) => {
        if (item.key === "label") {
          item.label = "Subject"
        } else {
          item.label = item.key.charAt(0).toUpperCase() + item.key.slice(1)
        }
      })

      setJobQualityAnalysis(filteredItems)
      setCheckingJobQuality(false)
      enqueueSnackbar("Job quality check done", { variant: "success" })
    })
  }

  const handlePromptConfirmDeleteJob = (event) => {
    const newConfig = {
      ...yesNoDeleteJobConfig,
      openPrompt: true,
      handleConfirm: () => handleDeleteJobConfirmed(jobId),
    }
    setYesNoDeleteJobConfig(newConfig)
  }

  const handlePromptDeleteFile = (fileName) => {
    const newConfig = {
      ...yesNoDeleteFileConfig,
      openPrompt: true,
      handleConfirm: () => handleDeleteFile({ fileName, job: values, jobId }),
    }
    setYesNoDeleteFileConfig(newConfig)
  }

  const handleDownloadFile = async (fileName) => {
    const folderPath = `accounts/${values.account_id}/jobs/${jobId}`
    const fullFilePath = `${folderPath}/${fileName}`
    const storageRef = ref(getStorage(), fullFilePath)

    const url = await getDownloadURL(storageRef)

    window.open(url, "_blank")
  }

  const handleDeleteFile = async ({ fileName, job, jobId }) => {
    const newValues = await jobServices.deleteJobFile({ job, jobId, fileName })

    setValues(newValues)
    setFileActions(fileActions + 1)

    setImageLoadInfo({
      docId: jobId,
      docType: "job",
      accountId: values.account_id,
      images: newValues.docs,
      fileInfo: newValues.file_info,
    })

    enqueueSnackbar(`Removed file ${fileName}`, { variant: "success" })
  }

  const handleViewPhotos = (selectedIndex) => {
    // The args for this need a job id provided, whereas normally 'values' does not contain the id
    setJobToShowPhotos({ ...values, id: jobId })
    setSelectedCarouselIndex(selectedIndex)
  }

  // Get all words from the subject, additional job details, and comments and create an array of unique lower case words
  // Strip out any common words, e.g. 'the', 'and', 'or', etc.
  const createSearchIndex = (job, comments) => {
    return dataServices.createSearchIndex([
      job.label,
      //job.description,
      //...comments.map((comment) => comment.comment),
    ])
  }

  const checkIfSelfRepairable = (jobValues, jobId) => {
    // Do not update self repair fields, if the reason value is set
    if (jobValues.self_repair_reason) {
      console.log("do not calc")
      return
    }
    console.log("calc")

    if (accountType === Roles.ACCOUNT_TYPE_CENTRE) {
      analyseIfSelfRepairable(jobValues).then((result) => {
        console.log("self-repairable?", result)

        db.collection("jobs")
          .doc(jobId)
          .update({
            self_repairable: result.self_repairable === "yes",
            self_repair_reason: result.reason,
          })

        setValues((curr) => {
          return {
            ...curr,
            self_repairable: result.self_repairable === "yes",
            self_repair_reason: result.reason,
          }
        })
      })
    }
  }

  // Save job

  const handleSubmit = async (event, addMultiple) => {
    event.preventDefault()

    if (isErrors({ values, fieldValidation })) {
      setShowFieldErrors(true)
      enqueueSnackbar("Check all information has been provided", {
        variant: "error",
      })
      return
    }

    if (isNew() && filesForNewJob.length === 0) {
      enqueueSnackbar("Please upload a photo of the job", { variant: "error" })
      return
    }

    setShowFieldErrors(false)

    if (isNew()) {
      const fileNames = filesForNewJob.map((file) => file.name)
      const fileInfo = fileNames.map((fileName) =>
        jobServices.createFileInfo({ claims, fileName: fileName })
      )

      const newRecord = {
        ...values,
        account_id: accountId,
        created: dataServices.serverTimestamp(),
        modified: dataServices.serverTimestamp(),
        docs: fileNames,
        // Who uploaded the file and when
        file_info: fileInfo,
        search_index: createSearchIndex(values, comments),
        history: [
          {
            date: dataServices.localTimestamp(),
            user: getAuth().currentUser.email,
            action: "Created",
            status: values.status,
          },
        ],
      }

      const docRef = await db.collection("jobs").add(newRecord)
      setJobId(docRef.id)
      setDocRef(docRef)

      const filePath = `accounts/${accountId}/jobs/${docRef.id}`
      await fileServices.saveJobAttachments(filesForNewJob, filePath)

      enqueueSnackbar("Created", { variant: "success" })

      setImageLoadInfo({
        docId: docRef.id,
        docType: "job",
        accountId: accountId,
        images: fileNames,
        fileInfo,
      })

      if (addMultiple) {
        setValues(initialValues())
        setJobId("")
      } else {
        setValues({
          ...newRecord,
          created: dataServices.localTimestamp(),
          modified: dataServices.localTimestamp(),
        })
        setTitle(newRecord.label)

        checkIfSelfRepairable(newRecord, docRef.id)
      }
    } else {
      const updateRecord = {
        ...values,
        search_index: createSearchIndex(values, comments),
        modified: dataServices.serverTimestamp(),
      }

      if (updateRecord.docs.length === 0) {
        enqueueSnackbar("Please upload a photo of the job", { variant: "info" })
      }

      // TEMP code to remove now unused attributes
      delete updateRecord.check_list_data
      delete updateRecord.check_list_id

      // See if job's centre_id has changed, and if so update
      // any actions that are associated with the job since they
      // store the centre_id

      if (values.centre_id !== originalCentreId) {
        let query = db
          .collection("actions")
          .where("parent_id", "==", jobId)
          .where("type", "==", "job")
          .where("account_id", "==", values.account_id)

        if (claims.account_type === Roles.SUPPLIER) {
          query = query.where("supplier_account_id", "==", claims.account_id)
        }

        query
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              db.collection("actions")
                .doc(doc.id)
                .update({ centre_id: values.centre_id })
            })
          })
          .catch((error) => {
            console.error("Error getting actions", error)
          })
      }

      await db
        .collection("jobs")
        .doc(jobId)
        .update(updateRecord)
        .then(enqueueSnackbar("Saved", { variant: "success" }))

      if (claims.roles.includes(Roles.JOB_ADMIN)) {
        checkIfSelfRepairable(updateRecord, jobId)
      }
    }
    //}

    // Clear this
    setJobQualityAnalysis([])
  }

  const handleAllocateJob = () => {
    setAllocateDialogOpen(true)
  }

  /**
   * Handle the allocation of a job to a work order
   * @param {string} workOrderId The new work order to which the job is allocated
   */
  const handleAllocated = (workOrderId) => {
    const newValues = { ...values, work_order_id: workOrderId }

    // Load supplier to which this was allocated, so that the supplier name appears
    // in the work order combo box
    db.collection("work_orders")
      .doc(workOrderId)
      .get()
      .then((doc) => {
        const data = doc.data()
        db.collection("suppliers")
          .doc(data.supplier_id)
          .get()
          .then((doc) => {
            const supplier = { id: doc.id, ...doc.data() }
            setSupplier(supplier)
          })
      })

    setValues(newValues)
    setAllocateDialogOpen(false)

    // Force reloading the page, even if this is the page we are already on
    history.push(`/JobEdit/${jobId}`)
  }

  const handleNavigate = ({ direction }) => {
    const queryParams = jobServices.getJobQueryParams({
      direction,
      pagination: pag,
      currentUser,
    })

    const { queryConstraints, queryMods } =
      jobServices.createJobQuery(queryParams)

    const dataQueryClauses = []

    const sortOrder =
      direction === "next" ? pag.order : pag.order === "asc" ? "desc" : "asc"

    dataQueryClauses.push(orderBy(pag.orderBy, sortOrder))
    dataQueryClauses.push(limit(1))
    queryMods.push(`order by ${pag.orderBy} ${pag.order} limit 1`)

    dataQueryClauses.push(startAfter(docRef))
    queryMods.push(`start after ${docRef.id}`)

    const dataQuery = query(
      collection(db, "jobs"),
      ...[...queryConstraints, ...dataQueryClauses]
    )

    getDocs(dataQuery).then((querySnapshot) => {
      // Clear out any image selection
      setSelectedCarouselIndex(undefined)
      setJobToShowPhotos(null)
      setImageLoadInfo(undefined)

      querySnapshot.forEach((doc) => {
        const data = doc.data()
        setComments([])
        history.push(`/JobEdit/${doc.id}`)
      })
    })
  }

  // Check if a file can be delete
  // 1. If there's no info, assume the file was captured before we started storing file info, e.g. email + date, and
  //    there if its a centre they can delete it, but suppliers cannot.
  // 2. suppliers can only delete their own photos.
  const isFileDeleteable = ({ isSupplierViewing, info, values }) => {
    let canDelete = true

    if (info) {
      if (isSupplierViewing) {
        canDelete =
          info.account_id === accountId &&
          values.status !== jobServices.JOB_STATUS_CLOSED
      }
    } else {
      if (isSupplierViewing) {
        canDelete = false
      }
    }

    return canDelete
  }

  return (
    <>
      <ProgressBackdrop open={isShowProgress} />

      {allocateDialogOpen && (
        <AllocateJobsDialog
          open={allocateDialogOpen}
          handleAllocated={(workOrderId) => handleAllocated(workOrderId)}
          handleClose={() => setAllocateDialogOpen(false)}
          jobsToAllocate={[{ id: jobId, ...values }]}
        />
      )}

      {showHistory && (
        <History
          open={showHistory}
          setOpen={setShowHistory}
          historyObj={values}
        />
      )}

      {showAction && (
        <ActionDialog
          open={showAction}
          setOpen={setShowAction}
          parentId={jobId}
          parentType={"job"}
          centreId={values.centre_id}
        />
      )}

      <YesNo config={yesNoDeleteJobConfig} />

      <YesNo config={yesNoDeleteFileConfig} />

      <Form>
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="selected-files"
          multiple
          type="file"
          ref={addFileRef}
          onChange={async (e) => {
            setShowProgress(true)
            await handleStoreFile(e)
          }}
        />

        <Menu
          anchorEl={jobStatusMenuEl}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          id={"status-menu"}
          keepMounted
          open={jobStatusMenuEl !== null}
          onClose={() => setJobStatusMenuEl(null)}
        >
          {jobStatusMenuItems}
        </Menu>

        <Box display="flex">
          <Stack
            direction="row"
            gap={1}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Controls.JobStatusChip
              status={values.status}
              showBadge={hasNonClosedJobActions}
            />
            {jobId && (
              <Box>
                <IconButton
                  onClick={(event) => setJobStatusMenuEl(event.currentTarget)}
                >
                  <MoreVertIcon />
                </IconButton>
              </Box>
            )}
            {location?.state?.context === undefined && jobId && (
              <>
                <Box>
                  <IconButton
                    onClick={() => handleNavigate({ direction: "prev" })}
                  >
                    <NavigateBeforeIcon />
                  </IconButton>
                </Box>
                <Box>
                  <IconButton
                    onClick={() => handleNavigate({ direction: "next" })}
                  >
                    <NavigateNextIcon />
                  </IconButton>
                </Box>
              </>
            )}

            {location?.state?.context && (
              <Box sx={{ width: "300px" }}>
                <Controls.Select
                  name="job"
                  label="Jobs in work order"
                  isNoneOption={false}
                  value={jobId}
                  onChange={(event) => {
                    history.push({
                      pathname: `/JobEdit/${event.target.value}`,
                      state: { context: location.state },
                    })
                  }}
                  options={jobs.map((job) => ({
                    id: job.id,
                    title: job.label,
                  }))}
                />
              </Box>
            )}
          </Stack>

          <Box sx={{ display: "flex", ml: "auto" }}>
            <Controls.CreatedLabel
              value={values.created}
              history={values.history}
            />
          </Box>
        </Box>

        <Stack direction="row" gap={5}>
          <Stack
            direction="column"
            gap={2}
            sx={{
              mt: "20px",
              mb: "20px",
              maxWidth: "400px",
              width: "100%",
            }}
          >
            <SubHeading title="Job Details" />
            <Controls.TextInput
              name="label"
              label="Subject"
              autoFocus={true}
              value={values.label}
              showError={showFieldErrors}
              errorFuncs={fieldValidation["label"]}
              onChange={handleInputChange}
              disabled={isSupplierViewing}
            />
            {selfRepairFields.visible && (
              <Stack gap={1} sx={{ mt: 0 }}>
                <Controls.Checkbox
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      <Typography>Self-repairable</Typography>
                      {values.self_repairable && (
                        <HomeRepairService
                          fontSize="small"
                          sx={{ color: colors.pink[200] }}
                        />
                      )}
                    </Box>
                  }
                  checked={values.self_repairable}
                  disabled={selfRepairFields.disabled}
                  onChange={(e) => {
                    setValues((curr) => {
                      const newValues = {
                        ...curr,
                        self_repairable: e.target.value,
                      }
                      return newValues
                    })
                  }}
                />

                <Controls.TextInput
                  name="self_repair_reason"
                  label="Reason"
                  value={values.self_repair_reason}
                  multiline
                  disabled={selfRepairFields.disabled}
                  onChange={handleInputChange}
                />
              </Stack>
            )}
            {userCentreIds && (
              <Controls.CentreCombobox
                name="centre_id"
                label="Centre"
                readonly={isSupplierViewing}
                value={values.centre_id}
                // Need to load drop down based on account id of this job record not the
                // current user, because the current user could either be a user from
                // this account, or a supplier from another account
                accountId={values.account_id}
                onChange={handleInputChange}
                centreIdsFilter={userCentreIds}
                showError={showFieldErrors}
                errorFuncs={fieldValidation["centre_id"]}
              />
            )}
            <Controls.ComboBox
              name="location"
              label="Location"
              value={values.location}
              items={locations}
              onChange={handleInputChange}
              disabled={isSupplierViewing}
              showError={showFieldErrors}
              errorFuncs={fieldValidation["location"]}
            />
            <Controls.Select
              name="category"
              label="Category"
              value={values.category}
              onChange={handleInputChange}
              options={jobTypes}
              disabled={isSupplierViewing}
              showError={showFieldErrors}
              errorFuncs={fieldValidation["category"]}
            />
            {priorities && priorities.lookup_values && (
              <Controls.Select
                name="priority"
                label="Priority"
                value={values.priority}
                noneValue={0}
                onChange={handleInputChange}
                options={priorities.lookup_values.map((v) => ({
                  id: v.id,
                  title: `${v.title} - ${v.description}`,
                }))}
                disabled={isSupplierViewing}
                showError={showFieldErrors}
                errorFuncs={fieldValidation["priority"]}
              />
            )}
            <Controls.UserAutoComplete
              name="user_id"
              label="Job Owner"
              accountId={accountId}
              userId={values.user_id}
              handleChange={handleJobOwnerChange}
              handleClear={handleJobOwnerClear}
              showError={showFieldErrors}
              errorFuncs={fieldValidation["user_id"]}
            />
            {showAdminFields && values.work_order_id && values.supplier_id && (
              <Controls.SupplierCombobox
                name="supplier_id"
                label="Supplier"
                // Suppliers (when logged in) cannot change the supplier details.
                // If the job is assigned to a work order the supplier cannot be changed
                readonly={isSupplierViewing || values.work_order_id !== ""}
                value={values.supplier_id}
                // Need to load drop down based on account id of this job record not the
                // current user, because the current user could either be a user from
                // this account, or a supplier from another account
                accountId={values.account_id}
                onChange={handleInputChange}
                isSaved={!isNew()}
              />
            )}
            {showAdminFields &&
              values.work_order_id &&
              values.maint_user_id && (
                <UserCombobox
                  name="maint_user_id"
                  label="Allocated To"
                  value={values.maint_user_id}
                  readonly={true}
                  accountId={accountId}
                  userIdsFilter={[values.maint_user_id]}
                />
              )}
            {jobId && values.work_order_id ? (
              <>
                <Controls.WorkOrderCombobox
                  name="work_order_id"
                  label="Work Order"
                  value={values.work_order_id}
                  // Used to trigger a reload of this combobox
                  reloadValue={workOrderModified}
                  accountId={values.account_id}
                  accountType={accountType}
                  readonly={true}
                  icon={AssignmentIcon}
                  isSaved={!isNew()}
                  isShowOpenLink={userRoles.includes(Roles.JOB_ADMIN)}
                  allocatedSupplier={supplier}
                  workOrderStatus={values.work_order_id && workOrderStatus}
                />

                {!values.work_order_id && (
                  <Typography variant="caption">
                    &nbsp;&nbsp;Not assigned to a work order yet
                  </Typography>
                )}
              </>
            ) : (
              <Alert severity="info">Job not allocated to work order</Alert>
            )}
            {/* {showAdminFields && (
              <Controls.Checkbox
                name="supply_parts"
                label="Supply Parts?"
                value={values.supply_parts}
                onChange={handleInputChange}
                disabled={isSupplierViewing}
              />
            )} */}
          </Stack>
          {/* <Box>
            {imageLoadInfo && (
              <ScrollingImageList
                imageLoadInfo={imageLoadInfo}
                selectedFileName={values.docs[selectedCarouselIndex]}
              />
            )}
          </Box> */}
        </Stack>

        <Stack
          direction="column"
          gap={3}
          sx={{ mt: "20px", maxWidth: "600px" }}
        >
          <Controls.TextInput
            name="description"
            label="Job Details and Expected Fix"
            multiline={true}
            helperText={
              "Describe the problem and what a successful fix looks like"
            }
            value={values.description}
            showError={showFieldErrors}
            errorFuncs={fieldValidation["description"]}
            onChange={handleInputChange}
            disabled={isSupplierViewing}
          />
          {jobId && isSupplierViewing === false && (
            <CommentArea
              comments={comments}
              isSupplierViewing={isSupplierViewing}
              values={values}
              jobId={jobId}
              userEmail={userEmail}
            />
          )}
        </Stack>

        {!jobId && (
          <FileStage files={filesForNewJob} setFiles={setFilesForNewJob} />
        )}

        <JobQualityAnalysis
          jobQualityAnalysis={jobQualityAnalysis}
          jobRegulations={jobRegulations}
          jobPrevention={jobPrevention}
          jobRiskAssessment={jobRiskAssessment}
          isCheckingJobQuality={isCheckingJobQuality}
          handleUpdateJobField={handleUpdateJobField}
          values={values}
        />
        <Box sx={{ mt: "40px", mb: "40px" }}>
          <Stack direction="column" gap={2}>
            {jobId && (
              <ActionPanels
                parentType={"job"}
                parentId={jobId}
                parentAccountId={values.account_id}
              />
            )}

            {jobId && (
              <Box>
                <Controls.Button
                  text="Create Action"
                  onClick={() => setShowAction(true)}
                  endIcon={<icons.ActionIcon />}
                  tooltip="Create an action for this job"
                />
              </Box>
            )}
          </Stack>
        </Box>

        <Stack direction="column">
          {isShowProgress && <CircularProgress />}
          <SubHeading title="Job Photos" />
          {values.docs.length === 0 && (
            <>
              {showFieldErrors && (
                <Box sx={{ m: "5px", maxWidth: "500px" }}>
                  <Alert severity="error">
                    Please upload at least one file
                  </Alert>
                </Box>
              )}
              {!showFieldErrors && (
                <Typography variant="body2" color="text.secondary">
                  No photos uploaded yet
                </Typography>
              )}
            </>
          )}
          <List dense={true}>
            {values.docs.map((fileName, index) => {
              const info = values.file_info?.find(
                (item) => item.file_name === fileName
              )

              // Delete is only allowed under these conditions
              // 1. If there's no file info, assume it was created pre-having file info available per file,
              //    or its in a part of the app where its centre-usage only, e.g. assets and its only the
              //    centre going to be using it.
              // 2. For centres, any file can be deleted.
              // 3. For suppliers, any file from your account where the job is not Closed.

              let canDelete = isFileDeleteable({
                isSupplierViewing,
                info,
                values,
              })

              return (
                <FileListItem
                  key={fileName}
                  info={info}
                  canDelete={canDelete}
                  handleViewPhotos={() => handleViewPhotos(index)}
                  fileName={fileName}
                  selected={selectedCarouselIndex === index}
                  handlePromptDeleteFile={() =>
                    handlePromptDeleteFile(fileName)
                  }
                  handleDownloadFile={() => handleDownloadFile(fileName)}
                  showSource={true}
                  sourceAccountId={values.account_id}
                />
              )
            })}
          </List>
          <Box sx={{ mb: "15px" }}>
            {jobId && (
              <Controls.Button
                text="Upload Photo"
                type="button"
                onClick={() => handleUploadFile()}
                endIcon={<icons.AttachFileIcon />}
                tooltip="Upload photos for this job"
              />
            )}
          </Box>
          {imageLoadInfo && (
            <ScrollingImageList
              imageLoadInfo={imageLoadInfo}
              selectedFileName={values.docs[selectedCarouselIndex]}
            />
          )}
        </Stack>

        <Divider sx={{ margin: 2 }} />

        <NavButtons>
          {!isSupplierViewing && (
            <Controls.Button
              text="AI Assist"
              tooltip="See suggestions on how to improve this Job information"
              type="button"
              onClick={handleAnalyseJobQuality}
              endIcon={<TipsAndUpdatesIcon />}
            />
          )}

          <Controls.Button
            text="Analyse Self-repairable"
            onClick={handleAnalyseSelfRepairable}
          />

          {!isNew() && !isSupplierViewing && (
            <Controls.Button
              text="Delete"
              type="button"
              onClick={handlePromptConfirmDeleteJob}
              endIcon={<icons.DeleteIcon />}
            />
          )}

          {jobId && (
            <Controls.Button
              text="Print"
              type="button"
              onClick={handlePrint}
              endIcon={<icons.PrintIcon />}
              tooltip="Create a browser print preview of this job"
            />
          )}

          {jobId && accountType === Roles.ACCOUNT_TYPE_CENTRE && (
            <Controls.Button
              text="History"
              onClick={() => setShowHistory(true)}
              tooltip="View history of changes to this job"
            />
          )}

          {jobId &&
            accountType === Roles.ACCOUNT_TYPE_CENTRE &&
            Roles.canAllocateJobs(userRoles) && (
              <Controls.Button
                text="Allocate"
                type="button"
                onClick={handleAllocateJob}
                endIcon={<AssignmentIcon />}
                tooltip="Allocate job to a supplier"
                disabled={!Boolean(Roles.canAllocateJobs(userRoles))}
              />
            )}

          {!isSupplierViewing && (
            <Controls.Button
              type="submit"
              text="Save"
              onClick={(event) => handleSubmit(event, false)}
              endIcon={<icons.SaveIcon />}
            />
          )}
        </NavButtons>
      </Form>
    </>
  )
}

const CommentArea = ({
  isSupplierViewing,
  comments,
  values,
  jobId,
  userEmail,
}) => {
  const [comment, setComment] = useState({ comment: "" })

  const [commentFocus, setCommentFocus] = useState(false)

  const commentRef = createRef()

  const clearComment = () => {
    setComment({ comment: "" })
  }

  const handleEditComment = (id) => {
    setComment(comments.find((comment) => comment.id === id))
    commentRef.current.focus()
  }

  const handleAddComment = async (comment) => {
    await dataServices.addCommentToJob({
      comment,
      supplierId: values.supplier_id,
      accountId: values.account_id,
      jobId: jobId,
      email: getAuth().currentUser.email,
    })

    clearComment()
  }

  return (
    <Box>
      <SubHeading title="Comments" />
      {isSupplierViewing === false && (
        <Controls.TextInput
          name="comment"
          label={(comment && comment.id && "Edit Comment") || "Add Comment"}
          inputRef={commentRef}
          multiline={true}
          value={comment.comment}
          onChange={(event) => {
            if (commentFocus) {
              const newComment = {
                ...comment,
                comment: event.target.value,
              }
              setComment(newComment)
            }
          }}
          onFocus={() => setCommentFocus(true)}
        />
      )}

      {commentFocus && (
        <Stack direction="row" gap={1}>
          <Controls.Button
            text={(comment && comment.id && "Save") || "Add Comment"}
            onClick={() => {
              handleAddComment(comment)
              setCommentFocus(false)
            }}
            disabled={comment === ""}
          />
          <Controls.Button
            text="Cancel"
            onClick={() => {
              setCommentFocus(false)
              clearComment()
            }}
          />
        </Stack>
      )}

      <Box sx={{ maxWidth: "650px" }}>
        {comments &&
          comments.map((comment) => (
            <Comment
              key={comment.id}
              comment={comment}
              handleEditComment={() => handleEditComment(comment.id)}
              userEmail={userEmail}
            />
          ))}
      </Box>
    </Box>
  )
}

const commentStyles = {
  createdBy: {
    fontWeight: "bold",
    marginRight: spacing(1),
  },
  comment: {
    marginLeft: spacing(1),
    marginTop: spacing(2),
    display: "flex",
    flexDirection: "row",
  },
}

const Comment = (props) => {
  const { comment, handleEditComment, userEmail } = props

  const [anchorEl, setAnchorEl] = useState(null)

  const handleDeleteComment = (id) => {
    db.collection("comments").doc(id).delete()
  }

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={anchorEl !== null}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => handleDeleteComment(comment.id)}>
          Delete
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null) // close menu
            handleEditComment()
          }}
        >
          Edit
        </MenuItem>
      </Menu>
      <Box key={comment.id} sx={commentStyles.comment}>
        <Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box>
              <Typography variant="caption" sx={commentStyles.createdBy}>
                {comment.created_by}
              </Typography>
            </Box>
            {comment.created && (
              <Typography variant="caption" color="#bbb">
                <ReactTimeAgo date={comment.created.toDate()} locale="en-AU" />
              </Typography>
            )}
          </Box>
          <Box>
            <Typography>{comment.comment}</Typography>
          </Box>
        </Box>
        {userEmail === comment.created_by && (
          <Box sx={{ display: "flex", ml: "auto" }}>
            <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
              <MoreVertIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    </>
  )
}

export default withRouter(JobEditForm)
