import React, { useState, useEffect, useMemo } from "react"
import Grid from "@mui/material/Grid"
import Controls from "./controls/Controls"
import { useForm, Form } from "./useForm"
import * as dataServices from "../pages/services/dataServices"
import EmailIcon from "@mui/icons-material/Email"
import PhoneIcon from "@mui/icons-material/Phone"
import db from "../Firestore"
import { useSnackbar } from "notistack"
import { useHistory, withRouter } from "react-router-dom"
import firebase from "firebase/compat/app"
import _ from "lodash"
import {
  FormControl,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Box,
  Typography,
  Autocomplete,
} from "@mui/material"
import YesNo from "./YesNo"
import * as Roles from "../pages/services/roleServices"
import * as icons from "../icons"
import NavButtons from "./NavButtons"
import { J4J_YELLOW } from "../pages/services/colorServices"
import SubHeading from "./SubHeading"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { isNonEmptyString, isErrors } from "./controls/FieldValidation"

const initialValues = () => {
  return {
    first_name: "",
    last_name: "",
    name: "",
    email: "",
    phone: "",
    roles: [],
    supplier_id: "",
    created: dataServices.localTimestamp(),
    modified: dataServices.localTimestamp(),
    centres: [],
    active: true,

    // For filtering the job summary page
    job_filters: [],
  }
}

function UserEditForm(props) {
  const [addMultiple, setAddMultiple] = React.useState(false)

  const { setTitle } = props

  const { enqueueSnackbar } = useSnackbar()

  const [userId, setUserId] = useState("")

  const [centres, setCentres] = useState([])

  const history = useHistory()

  const isNew = () => props.id === undefined && userId === ""

  const { values, setValues, handleInputChange } = useForm(initialValues())

  const COLLECTION_NAME = "users"

  const [isEditable, setEditable] = useState(false)

  const [accountType, setAccountType] = useState()

  const [showFieldErrors, setShowFieldErrors] = useState(false)

  const fieldValidation = {
    // email is disabled, so user shouldn't be able to change it, but include validation anyway.
    email: [isNonEmptyString],
    name: [isNonEmptyString],
  }

  const [yesNoConfig, setYesNoConfig] = useState({
    title: "Delete",
    description: "This delete is permanent",
    openPrompt: false,

    // this method is set when we prompt for deletion
    handleConfirm: null,
  })

  const userCentreOptions = useMemo(() => {
    if (values.centres && centres) {
      return centres
        .filter((c) => values.centres.includes(c.id))
        .map((c) => ({ value: c.id, label: c.name }))
    }
    return []
  }, [values.centres, centres])

  const roles = [
    { value: Roles.ADMIN, label: "Admin" },
    {
      value: Roles.JOB_ADMIN,
      label: "Job Admin",
    },
    { value: Roles.JOB_USER, label: "Job User" },
    { value: Roles.JOB_ALLOCATOR, label: "Job Allocator" },
    {
      value: Roles.WORK_ORDER_APPROVER,
      label: "Work Order Approver",
    },
    { value: Roles.FINANCE, label: "Finance" },
    { value: Roles.MAINTENANCE, label: "Maintenance" },
  ]

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      user.getIdTokenResult(false).then((token) => {
        setAccountType(token.claims.account_type)

        const isSupplier = (token.claims.account_type =
          Roles.ACCOUNT_TYPE_SUPPLIER)
        const isCentreAdmin =
          token.claims.account_typ === Roles.ACCOUNT_TYPE_CENTRE &&
          token.claims.roles.includes(Roles.ADMIN)
        setEditable(isSupplier || isCentreAdmin)

        dataServices
          .getCentresByAccountId(token.claims.account_id, true)
          .then((centres) =>
            setCentres(centres.sort((a, b) => a.name.localeCompare(b.name)))
          )
      })
    })

    return unsub
  }, [])

  // Load user record

  useEffect(() => {
    if (props.id != null) {
      setUserId(props.id)

      db.collection(COLLECTION_NAME)
        .doc(props.id)
        .get()
        .then((snapshot) => {
          const userRec = _.merge({}, initialValues(), snapshot.data())
          setValues(userRec)
        })
    }
  }, [props.id])

  const handleSelectCentre = (event) => {
    if (event.target.checked) {
      // Adding
      const newCentreIds = [...values.centres]
      newCentreIds.push(event.target.value)
      const newValues = {
        ...values,
        centres: newCentreIds,
      }
      setValues(newValues)
    } else {
      // Removing
      const newCentreIds = values.centres.filter(
        (centreId) => centreId !== event.target.value
      )
      const newValues = {
        ...values,
        centres: newCentreIds,
      }
      setValues(newValues)
    }
  }

  const handleSelectRole = (event) => {
    if (event.target.checked) {
      // Adding
      const newRoles = [...values.roles]
      newRoles.push(event.target.value)
      const newValues = {
        ...values,
        roles: newRoles,
      }
      setValues(newValues)
    } else {
      // Removing
      const newRoles = values.roles.filter(
        (roleId) => roleId !== event.target.value
      )
      const newValues = {
        ...values,
        roles: newRoles,
      }
      setValues(newValues)
    }
  }

  useEffect(() => setTitle(values.name), [values.name, setTitle])

  // 'confirmed' is true or false
  const handleDeleteConfirmed = () => {
    // Reset prompt flag, so if we'd clicked No, we can still click Delete again and get prompted
    const newPromptConfig = {
      ...yesNoConfig,
      openPrompt: false,
    }
    setYesNoConfig(newPromptConfig)

    if (userId !== undefined && userId !== "" && userId !== null) {
      db.collection(COLLECTION_NAME)
        .doc(userId)
        .delete()
        .then(history.goBack())
        .then(enqueueSnackbar("Deleted", { variant: "success" }))
    }
  }

  const handlePromptConfirmDelete = (event) => {
    event.preventDefault()

    const newPromptConfig = {
      ...yesNoConfig,
      openPrompt: true,
      handleConfirm: handleDeleteConfirmed,
    }
    setYesNoConfig(newPromptConfig)
  }

  // save user

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (isErrors({ values, fieldValidation })) {
      setShowFieldErrors(true)
      enqueueSnackbar("Check all information has been provided", {
        variant: "error",
      })
      return
    }

    setShowFieldErrors(false)

    if (isNew()) {
      dataServices
        .getCurrentUser()
        .then((user) => {
          const newRecord = {
            ...values,
            supplier_id: values.supplier_role ? values.supplier_id : null,
            account_id: user.account_id,
            created: dataServices.serverTimestamp(),
            modified: dataServices.serverTimestamp(),
          }

          db.collection(COLLECTION_NAME)
            .add(newRecord)
            .then((docRef) => setUserId(docRef.id))
            .then(enqueueSnackbar("Created", { variant: "success" }))
            .then(() => {
              if (addMultiple) {
                setValues(initialValues())
                setUserId("")
              } else {
                history.push("/users")
              }
            })
        })
        .catch(function (error) {
          console.error("Error:" + error)
          enqueueSnackbar("Error", { variant: "error " })
        })
    } else {
      const updateRecord = {
        ...values,
        supplier_id: values.supplier_role
          ? values.supplier_id
          : firebase.firestore.FieldValue.delete(),
        modified: dataServices.serverTimestamp(),
      }

      await db.collection(COLLECTION_NAME).doc(userId).update(updateRecord)

      enqueueSnackbar("Updated", { variant: "success" })
      history.push("/users")
    }
  }

  return (
    <>
      {yesNoConfig && <YesNo config={yesNoConfig} />}

      <Box sx={{ marginBottom: "30px" }}>
        <SubHeading title="User Details" />
      </Box>

      <Form>
        <Stack gap={2} sx={{ maxWidth: "400px" }}>
          <Controls.TextInput
            disabled={true}
            name="email"
            label="Email"
            value={values.email}
            icon={<EmailIcon />}
            onChange={handleInputChange}
            showError={showFieldErrors}
            errorFuncs={fieldValidation["email"]}
          />

          <Controls.TextInput
            disabled={!isEditable}
            name="name"
            label="Name"
            value={values.name}
            onChange={handleInputChange}
            showError={showFieldErrors}
            errorFuncs={fieldValidation["name"]}
          />

          <Controls.TextInput
            disabled={!isEditable}
            name="phone"
            label="Phone"
            value={values.phone}
            icon={<PhoneIcon />}
            onChange={handleInputChange}
          />

          <Box>
            <Controls.Checkbox
              label="Active"
              name="active"
              value={values.active}
              onChange={handleInputChange}
            />
            <Typography variant="caption" color="text.secondary">
              Inactive users cannot log in
            </Typography>
          </Box>

          <FormControl component="fieldset">
            <Stack gap={2}>
              <SubHeading title="Assign user role(s)" />
              <FormGroup>
                {roles.map((role) => (
                  <FormControlLabel
                    key={role.value}
                    control={
                      <Checkbox
                        disabled={!isEditable}
                        //color="primary"
                        sx={{ color: J4J_YELLOW }}
                        checked={values.roles.indexOf(role.value) !== -1}
                        value={role.value}
                        name="role"
                        onChange={handleSelectRole}
                      />
                    }
                    label={role.label}
                  />
                ))}
              </FormGroup>
            </Stack>
          </FormControl>
        </Stack>

        {accountType === Roles.ACCOUNT_TYPE_CENTRE && (
          <Stack gap={2} sx={{ my: "20px", width: "400px" }}>
            <SubHeading title="Assign centre access" />
            <Autocomplete
              multiple
              id="user_centres"
              options={centres.map((centre) => ({
                value: centre.id,
                label: centre.name,
              }))}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              getOptionLabel={(option) => option.label}
              value={userCentreOptions}
              onChange={(event, newValues) => {
                setValues({ ...values, centres: newValues.map((v) => v.value) })
              }}
              renderInput={(params) => (
                <Controls.TextInput
                  {...params}
                  label="Centres"
                  name="centres"
                  onChange={(e, val) => {
                    console.log("change", { e, val })
                  }}
                />
              )}
            />
          </Stack>
        )}

        {/* {accountType === Roles.ACCOUNT_TYPE_CENTRE && (
          <FormControl component="fieldset">
            <SubHeading title="Assign centre access" />
            <FormGroup>
              {centres.map((centre) => (
                <FormControlLabel
                  key={centre.id}
                  control={
                    <Checkbox
                      sx={{ color: J4J_YELLOW }}
                      checked={
                        values.centres !== null &&
                        values.centres !== undefined &&
                        values.centres.indexOf(centre.id) !== -1
                      }
                      value={centre.id}
                      name="centre"
                      onChange={handleSelectCentre}
                    />
                  }
                  label={centre.name}
                />
              ))}
            </FormGroup>
          </FormControl>
        )} */}

        <Grid item>
          <NavButtons>
            {!isNew() && isEditable ? (
              <Controls.Button
                text="Delete"
                type="button"
                onClick={handlePromptConfirmDelete}
                endIcon={<icons.DeleteIcon />}
              />
            ) : null}
            {isEditable && (
              <Controls.Button
                type="submit"
                text="Save"
                onClick={handleSubmit}
                endIcon={<icons.SaveIcon />}
              />
            )}
          </NavButtons>
        </Grid>

        <Controls.AddMultiple
          {...props}
          addMultiple={addMultiple}
          setAddMultiple={setAddMultiple}
        />
      </Form>
    </>
  )
}

export default withRouter(UserEditForm)
